<script setup lang="ts">

import WorkoutsAllAvailableList from "@/components/WorkoutsAllAvailableList.vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import BaseTitle from "@/components/BaseTitle.vue";
import FormButton from "@/components/FormButton.vue";
import PageHeader from "@/components/PageHeader.vue";

function onSubmit(event: Event) {
    event.preventDefault();
}
</script>

<template>
    <ContentWrapper>
        <BaseTitle>Co budeš cvičit?</BaseTitle>
        <form @submit="onSubmit" novalidate>
            <WorkoutsAllAvailableList />
            <div class="mt-4">
                <FormButton type="submit" class="w-full">Mám vybráno jdu bušit</FormButton>
            </div>
        </form>
    </ContentWrapper>
</template>

<style scoped>

</style>