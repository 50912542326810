import {ref, computed, toRaw} from 'vue'
import {defineStore} from 'pinia'

// Import the functions you need from the SDKs you need

import {initializeApp} from "firebase/app";
import {
    getAuth,
    signOut,
    sendSignInLinkToEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    onAuthStateChanged,
    setPersistence,
    browserLocalPersistence
} from "firebase/auth";
import {
    getFirestore,
    collection,
    query,
    where,
    getDoc,
    getDocs,
    onSnapshot,
    setDoc,
    doc,
    limit,
    addDoc,
    serverTimestamp
} from "firebase/firestore";
import {isSameDay, isSameMonth, isSameWeek} from "@/utils";
import type {TWorkoutIcon} from "@/icons/workout/iconWorkoutTypes";
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: import.meta.env.VITE_FIREBASE_APIKEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTHDOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECTID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGEBUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGINGSENDERID,
    appId: import.meta.env.VITE_FIREBASE_APPID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENTID ? import.meta.env.VITE_FIREBASE_MEASUREMENTID : undefined,
};

// Initialize Firebase

const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider("6LdQEmQqAAAAAPvo-NdSsfYq1ZU9zsYsaJOPgYi1"),
    isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
});

const FB_APP_ROOT = "APP_ROOT/pcFuzoMtfRpeoJlRao6Y"

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: import.meta.env.VITE_DOMAIN_ROOT + '/finishSignUp',
    //url: 'https://www.mamtuk.com/finishSignUp',
    // This must be true.
    handleCodeInApp: true,
};

export type ActivityUnit = "NUMBER" | "DISTANCE";

type PublicActivity = {
    activityTypeId: ActivityTypeId,
    name: string,
    unit: ActivityUnit,
    icon: TWorkoutIcon,
    logAmount: number, // Doporuceny/predvyplneny pocet jednotek, ktere by mel do aktivity uzivatel logovat.
}

export type ActivityTypeId = string;
export type UserId = string;

type FbDocumentId = string;

type ActivitySettings = {
    minLogAmount: number;
}

type WatchedUserData = {
    shareId: string,
    nickName: string,
}

type ActivityData = {
    activityTypeId: ActivityTypeId,
    uid: UserId,
    loggedAt: number, // timestamp in seconds
    valueTotal: number,
    valueDay: number,
    valueWeek: number
    valueMonth: number
    recordValueDay: number,
    recordValueDayAt: number, // timestamp in seconds,
    recordValueWeek: number,
    recordValueWeekAt: number, // timestamp in seconds,
    recordValueMonth: number,
    recordValueMonthAt: number, // timestamp in seconds,
}

type FbData = {
    user: {
        nick: string,
        shareId: string,
        watchedUsers: Record<UserId, WatchedUserData>
        activitySettings: Record<ActivityTypeId, ActivitySettings>
    },
    activityLog: {},
    publicActivity: Record<ActivityTypeId, PublicActivity>
}

type Store = {
    userLogging: boolean;
    currentUserId: UserId;
    fbData: FbData
    watchedActivity: Record<ActivityTypeId, Record<UserId, ActivityData>>
}

let watchUserActivityUnsubscribe: any = undefined;


export const useApp = defineStore('storeApp', () => {
    const store = ref<Store>({
        userLogging: false,
        currentUserId: "",
        fbData: {
            user: {
                nick: "",
                watchedUsers: {},
                activitySettings: {},
                shareId: "",
            },
            activityLog: {},
            publicActivity: {},
        },
        watchedActivity: {},
    });

    async function addWatchedUser(userShareId: string) {
        userShareId = userShareId.trim();
        const currentUserId = auth.currentUser?.uid;
        if (!currentUserId || !userShareId) {
            return;
        }

        let q:ReturnType<typeof query>;
        try {
            q = query(
                collection(firestore, FB_APP_ROOT + "/USER"),
                where("shareId", "==", userShareId),
                limit(1)
            );
        } catch (error) {
            console.error(error);
            return;
        }

        let docs: Awaited<ReturnType<typeof getDocs<any, any>>>;
        try{
            docs = await getDocs(q);
        } catch (error) {
            console.error("getDocs",error);
            return;
        }

        if (docs.size === 0) {
            console.error('Not found');
            return;
        }
        if (docs.size > 1) {
            console.error('More users with the same shareId', docs.size);
            return;
        }

        const watchedUserId = docs.docs[0].id;
        if (!watchedUserId) {
            console.error('User do not exists???');
            return;
        }

        const watchedUserNickName = docs.docs[0].data()?.nick ?? 'borec_' + watchedUserId.slice(0, 4);
        if (watchedUserId === currentUserId) {
            // Nemuzeme sledovat sami sebe
            console.error('Can not watch yourself');
            return;
        }
        if (store.value.fbData.user.watchedUsers[watchedUserId]) {
            console.error('Already in watch list');
            return;
        }

        let docRef: ReturnType<typeof doc>;
        try {
        docRef = doc(firestore, FB_APP_ROOT + '/USER/' + currentUserId + '/WATCH_USERS/' + watchedUserId);
        } catch (error) {
            console.error("doc",error);
            return;
        }
        try {
            await setDoc(docRef, {
                nick: watchedUserNickName,
            },{merge:true});
        } catch (error) {
            console.error("setDoc",error);
            return;
        }

        await refreshWatchedUsers(currentUserId);
    }

    async function subscribeToActivityLog(s: Array<UserId>) {
        if (watchUserActivityUnsubscribe) {
            await watchUserActivityUnsubscribe();
        }
        const q = query(
            collection(firestore, FB_APP_ROOT + "/ACTIVITY_LOG"),
            where("uid", "in", s),
            limit(100) // hard limit. (Pocet uzivatelu) x (pocet jejich aktivit) nesmi byt vetsi ne hard limit, jinak se nevrati vsechny dokumenty
        );
        watchUserActivityUnsubscribe = onSnapshot(q, (querySnapshot) => {
            const watchedActivity: typeof store.value.watchedActivity = {};
            querySnapshot.forEach((doc) => {
                if (!watchedActivity[doc.data().a_type]) {
                    watchedActivity[doc.data().a_type] = {};
                }
                watchedActivity[doc.data().a_type][doc.data().uid] = {
                    activityTypeId: doc.data().a_type,
                    uid: doc.data().uid,
                    loggedAt: doc.data().log_at?.seconds ?? 0,
                    recordValueDay: doc.data().rvd ?? 0,
                    recordValueDayAt: doc.data()?.rvd_at?.seconds ?? 0,
                    recordValueMonth: doc.data().rvm ?? 0,
                    recordValueMonthAt: doc.data()?.rvm_at?.seconds ?? 0,
                    recordValueWeek: doc.data().rvw ?? 0,
                    recordValueWeekAt: doc.data()?.rvw_at?.seconds ?? 0,
                    valueDay: doc.data().vd ?? 0,
                    valueMonth: doc.data().vm ?? 0,
                    valueTotal: doc.data().vt ?? 0,
                    valueWeek: doc.data().vw ?? 0,
                };
            });
            if (store.value.watchedActivity) {
                store.value.watchedActivity = watchedActivity;
            }
        });
    }

    async function loadActivitySettings(uid: UserId) {
        const activitySettings: typeof store.value.fbData['user']['activitySettings'] = {};
        let querySnapshot = await getDocs(collection(firestore, FB_APP_ROOT + '/USER/' + uid + "/ACTIVITY_SETTINGS"));
        querySnapshot.forEach((doc) => {
            activitySettings[doc.id] = {
                minLogAmount: doc.data().min_log_amount,
            }
        });
        store.value.fbData.user.activitySettings = activitySettings;
    }

    async function refreshWatchedUsers(currentUserId: UserId) {
        /*
          Seznam IDcek uzivatelu, ktere sleduji
        */
        const watchedUsers: typeof store.value.fbData['user']['watchedUsers'] = {};
        let querySnapshot = await getDocs(collection(firestore, FB_APP_ROOT + '/USER/' + currentUserId + "/WATCH_USERS"));
        querySnapshot.forEach((doc) => {
            watchedUsers[doc.id] = {
                nickName: doc.data().nick,
                shareId: doc.data().shareId
            }
        });
        store.value.fbData.user.watchedUsers = watchedUsers;

        await subscribeToActivityLog([currentUserId, ...Object.keys(watchedUsers)]);
    }

    async function initializeUser(uid: UserId) {
        let docRef = doc(firestore, FB_APP_ROOT + '/USER/' + uid);
        let docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
            console.log("User do not exists", uid);
            await setDoc(docRef, {
                nick: "borec_" + uid.slice(0, 4),
                shareId: uid.slice(0, 8)
            });
        }
        store.value.fbData.user.nick = docSnap.data()?.nick ?? "NA";
        store.value.fbData.user.shareId = docSnap.data()?.shareId ?? "";

        await loadPublicActivityList();

        await loadActivitySettings(uid);

        await refreshWatchedUsers(uid);

    }

    type WatchedUsers = Record<UserId, {
        nickName: string,
        shareId: string
    }>
    const watchedUsers = computed<WatchedUsers>(() => {
        return store.value.fbData.user.watchedUsers;
    })

    onAuthStateChanged(auth, async (user) => {
        store.value.userLogging = false;
        if (user) {
            await initializeUser(user.uid)
            store.value.currentUserId = user.uid;
        } else {
            store.value.currentUserId = "";
            if (watchUserActivityUnsubscribe) {
                watchUserActivityUnsubscribe();
            }
        }
    })

    type CurrentUser = {
        loggedIn: boolean,
        idToShare: string,
        nickName: string
    }
    const currentUser = computed<CurrentUser>(() => {
        return {
            loggedIn: store.value.currentUserId !== "",
            nickName: store.value.fbData?.user?.nick ?? "",
            idToShare: store.value.fbData?.user?.shareId ?? ""
        }
    })

    function getActivityDataFromLog(userId: UserId, activityTypeId: ActivityTypeId): ActivityData {
        let result: ActivityData = {
            activityTypeId: activityTypeId,
            uid: userId,
            loggedAt: 0,
            valueDay: 0,
            valueWeek: 0,
            valueTotal: 0,
            valueMonth: 0,
            recordValueWeekAt: 0,
            recordValueWeek: 0,
            recordValueMonthAt: 0,
            recordValueMonth: 0,
            recordValueDayAt: 0,
            recordValueDay: 0
        }

        if (userId && activityTypeId && store.value.watchedActivity[activityTypeId] && store.value.watchedActivity[activityTypeId][userId]) {
            result = structuredClone(toRaw(store.value.watchedActivity[activityTypeId][userId]));
        }

        const currentDate = new Date();
        const lastLoggedDate = new Date(result.loggedAt * 1000);// Bo Javascript jede v milisekundach

        if (result.loggedAt) {
            if (!isSameDay(currentDate, lastLoggedDate)) {
                // naposledy zalogovana hodnota nebyla zalogovana dneska
                result.valueDay = 0;
            }
            if (!isSameWeek(currentDate, lastLoggedDate)) {
                // naposledy zalogovana hodnota nebyla zalogovana v tomto tydnu
                result.valueWeek = 0;
            }
            if (!isSameMonth(currentDate, lastLoggedDate)) {
                // naposledy zalogovana hodnota nebyla zalogovana v tomto mesici
                result.valueMonth = 0;
            }
        }
        return result;
    }

    type ActivityLog = Record<ActivityTypeId, {
        activityData: ActivityData,
        activityName: string,
        watchedUsers: Record<UserId, {
            nickName: string,
            activityData: ActivityData,
        }>,
    }>
    const activityLog = computed<ActivityLog>(() => {
        const result: ActivityLog = {};

        for (let activityTypeId in store.value.fbData.publicActivity) {
            result[activityTypeId] = {
                activityData: getActivityDataFromLog(store.value.currentUserId, activityTypeId),
                activityName: store.value.fbData.publicActivity[activityTypeId]?.name ?? "neznama aktivita",
                watchedUsers: {},
            };
            for (let watchedUserId in store.value.fbData.user.watchedUsers) {
                result[activityTypeId].watchedUsers[watchedUserId] = {
                    activityData: getActivityDataFromLog(watchedUserId, activityTypeId),
                    nickName: store.value.fbData.user.watchedUsers[watchedUserId].nickName
                }
            }
        }


        return result;
    })

    async function registerUser(email: string) {
        try {
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            window.localStorage.setItem('emailForSignIn', email);
            console.log('SUCCESS');
        } catch (e) {
            console.error(e);
        }
    }

    async function finishSighIn(email: string) {
        console.log('finishSighIn');


        if (isSignInWithEmailLink(auth, window.location.href)) {
            // The client SDK will parse the code from the link for you.
            console.log('signInWithEmailLink', window.location.href);
            try {
                await setPersistence(auth, browserLocalPersistence);
                const result = await signInWithEmailLink(auth, email, window.location.href);
                console.log('signed in. result', {...result});
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
                // You can access the new user via result.user
                // Additional user info profile not available via:
                // result.additionalUserInfo.profile == null
                // You can check if the user is new or existing:
                // result.additionalUserInfo.isNewUser
            } catch (error) {
                console.log('error', error);
                // Some error occurred, you can inspect the code: error.code
                // Common errors could be invalid email and invalid or expired OTPs.
            }
        } else {
            console.log('isSignInWithEmailLink: false');
        }
    }

    async function loadPublicActivityList() {
        const publicActivity: typeof store.value.fbData['publicActivity'] = {};
        const q = query(
            collection(firestore, FB_APP_ROOT + "/ACTIVITY_TYPE"),
            where('public', '==', true),
            limit(10) // hard limit
        );
        let querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            let activityUnit: ActivityUnit;
            switch (doc.data().unit) {
                case "DIST": {
                    // Vzdalenost v metrech
                    activityUnit = "DISTANCE"
                    break;
                }
                default: {
                    activityUnit = "NUMBER"
                }
            }
            publicActivity[doc.id] = {
                activityTypeId: doc.id,
                unit: activityUnit,
                logAmount: doc.data().log_amount,
                icon: doc.data().icon,
                name: doc.data().name,
            }
        });
        store.value.fbData.publicActivity = publicActivity;
    }

    const publicActivityList = computed(() => {
        return store.value.fbData.publicActivity
    })

    async function updateActivitySettingsForCurrentUser(activityTypeId: ActivityTypeId, minLogAmount: number) {
        if (!store.value.currentUserId || activityTypeId.trim() === "") {
            return;
        }
        if (minLogAmount <= 0) {
            minLogAmount = 1;
        }
        const docRef = doc(firestore, FB_APP_ROOT + "/USER/" + store.value.currentUserId + "/ACTIVITY_SETTINGS", activityTypeId);
        await setDoc(docRef, {min_log_amount: minLogAmount}, {merge: true});
        store.value.fbData.user.activitySettings[activityTypeId].minLogAmount = minLogAmount
    }

    async function authSignOut() {
        await signOut(auth);
    }

    async function changeMyNickName(newNickName: string) {
        newNickName = newNickName.trim();
        if (newNickName.length > 20) {
            console.error('Too long');
            return;
        }

        const currentUserId = store.value.currentUserId;
        if (!currentUserId) {
            return;
        }
        let docRef: ReturnType<typeof doc>;
        try {
            docRef = doc(firestore, FB_APP_ROOT + '/USER/' + currentUserId);
        } catch (error) {
            console.error("doc",error);
            return;
        }
        try {
            await setDoc(docRef, {
                nick: newNickName,
            },{ merge: true });
        } catch (error) {
            console.error("setDoc",error);
            return;
        }
    }

    async function logMyActivity(activityTypeId: ActivityTypeId, logAmount: number) {
        if (!store.value.currentUserId) {
            return;
        }


        const activityData = getActivityDataFromLog(store.value.currentUserId, activityTypeId);

        let newActivityData: Record<string, any> = {
            a_type: activityTypeId,
            uid: store.value.currentUserId,
            log_at: serverTimestamp(),
            vd: activityData.valueDay + logAmount,
            vm: activityData.valueMonth + logAmount,
            vt: activityData.valueTotal + logAmount,
            vw: activityData.valueWeek + logAmount,
        }

        if (activityData.valueDay + logAmount > activityData.recordValueDay) {
            newActivityData['rvd'] = activityData.valueDay + logAmount;
            newActivityData['rvd_at'] = serverTimestamp();
        }

        if (activityData.valueWeek + logAmount > activityData.recordValueWeek) {
            newActivityData['rvw'] = activityData.valueWeek + logAmount;
            newActivityData['rvw_at'] = serverTimestamp();
        }

        if (activityData.valueMonth + logAmount > activityData.recordValueMonth) {
            newActivityData['rvm'] = activityData.valueMonth + logAmount;
            newActivityData['rvm_at'] = serverTimestamp();
        }

        const q = query(
            collection(firestore, FB_APP_ROOT + "/ACTIVITY_LOG"),
            where('a_type', '==', activityTypeId), where("uid", "==", store.value.currentUserId),
            limit(1)
        );
        const querySnapshot = await getDocs(q);


        if (querySnapshot.empty) {
            // Uzivatel tuto aktivitu jeste nelogoval. Pridame novy dokument
            await addDoc(collection(firestore, FB_APP_ROOT + "/ACTIVITY_LOG"), newActivityData);
        } else {
            // Uzivatel tuto aktivitu uz logoval. Zmenime aktualni dokument
            // Musi byt {merge:true}, protoze newActivityData nemusi obsahovat vsechny data (napr. rvd, rvd_at,...)
            await setDoc(doc(firestore, FB_APP_ROOT + "/ACTIVITY_LOG/" + querySnapshot.docs[0].id), newActivityData,{merge:true});
        }

        await addDoc(collection(firestore, FB_APP_ROOT + "/ACTIVITY_LOG_DETAIL"), {
            a_type:activityTypeId,
            amount:logAmount,
            log_at:serverTimestamp(),
            uid:store.value.currentUserId,
        });

    }

    return {
        finishSighIn: finishSighIn,
        registerUser: registerUser,
        authSignOut: authSignOut,
        addWatchedUser: addWatchedUser,
        currentUser: currentUser,
        watchedUsers: watchedUsers,
        activityLog: activityLog,
        publicActivityList: publicActivityList,
        logMyActivity: logMyActivity,
        changeMyNickName:changeMyNickName,
    }
})
