<script setup lang="ts">

</script>

<template>
    <div class="px-4 max-w-96 mx-auto my-4">
        <slot></slot>
    </div>
</template>

<style scoped>

</style>