<script setup lang="ts" generic="T">
import {computed, ref} from "vue";
    import IconTick from "../icons/iconTick.svg"

    const elementInput = ref<HTMLInputElement | null>(null);
    type ModelValue = undefined extends T ? boolean : Array<T>;
    const inputValue = defineModel<ModelValue>({required: true});
    const props = withDefaults(defineProps<{
        value?: T;
        id: string;
        component?: "label" | "div";
    }>(), {
        component: "label"
    })

    const forAttribute = computed(() => {
        if (props.component === "label") {
            return props.id;
        }
    })
    const cssClasses = computed(() => {
        if (props.component === "label") {
            return "cursor-pointer";
        }
    })
</script>

<template>
    <component :is="props.component" :for="forAttribute" :class="cssClasses" class="w-7 h-7 items-center justify-center flex border-2 border-[--base1] rounded-lg bg-[--base3] relative overflow-hidden">
        <input :id="props.id" ref="elementInput" type="checkbox" v-model="inputValue" :value="props.value" class="peer bg-transparent outline-0 appearance-none w-px h-px absolute left-0 top-0 border-none p-0 -m-px" />
        <IconTick class="w-4 h-3 text-[--base1] peer-checked:opacity-100 opacity-0 transition-all" />
    </component>
</template>

<style scoped>

</style>