<script setup lang="ts">
import {computed} from "vue";
import {formatPerformanceDifference, performanceDifferenceColor} from "@/utils";
import UserAvatar from "@/components/UserAvatar.vue";
import type {ActivityUnit} from "@/stores/useApp";

const props = defineProps<{
    name: string;
    avatar?: string;
    performanceDifference: number;
    dataType: ActivityUnit
}>()
const formattedPerformance = computed(() => {
    return formatPerformanceDifference(props.performanceDifference, props.dataType);
})
const performanceColor = computed(() => {
    if (props.performanceDifference === 0) {
        return "bg-[--green]";
    }
    return performanceDifferenceColor(props.performanceDifference);
})
</script>

<template>
    <div>
        <UserAvatar :name="props.name" :avatar="props.avatar" class="cursor-default mx-auto" />
        <div :class="performanceColor" class="rounded-full text-center px-2 text-sm text-[--base3] -mt-2 cursor-default">{{formattedPerformance}}</div>
    </div>
</template>

<style scoped>

</style>