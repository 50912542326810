<script setup lang="ts">

import WorkoutGraph from "@/components/WorkoutGraph.vue";
import {computed, ref} from "vue";
import {formatMetersToKilometers} from "@/utils";
import FormButton from "@/components/FormButton.vue";
import WorkoutTileWatchedUser from "@/components/WorkoutTileWatchedUser.vue";
import BaseBox from "@/components/BaseBox.vue";
import IconWorkout from "@/icons/workout/IconWorkout.vue";
import {type ActivityTypeId, useApp, type UserId} from "@/stores/useApp";
import type {TWorkoutIcon} from "@/icons/workout/iconWorkoutTypes";

const props = defineProps<{activityTypeId:ActivityTypeId}>()
const app = useApp();

const icon = computed<TWorkoutIcon | null>(() => {
    return activitySettings.value.icon ?? null;
});

const currentActivity = computed(()=>{
   return app.activityLog[props.activityTypeId]?? undefined;
})

const activitySettings = computed(()=>{
   return app.publicActivityList[props.activityTypeId]?? undefined;
})

const logAmount = computed(()=>{
   return activitySettings.value?.logAmount?? 1
})

const toAddFormatted = computed(() => {
   let result: string|number = "";
    switch (activitySettings.value.unit) {
        case "NUMBER": {
           result = logAmount.value;
           break;
        }
        case "DISTANCE": {
           result = formatMetersToKilometers(logAmount.value);
           break;
        }
    }
    return result;
})

const todayPerformance = computed(()=>{
   return currentActivity.value?.activityData.valueDay?? 0;
})

const recordPerformanceDay = computed(()=>{
   return currentActivity.value?.activityData.recordValueDay?? 0;
})

const todayPerformanceFormatted = computed(() => {
   let result: string|number = "";
    switch (activitySettings.value.unit) {
        case "NUMBER": {
           result = todayPerformance.value;
           break;
        }
        case "DISTANCE": {
           result = formatMetersToKilometers(todayPerformance.value);
           break;
        }
    }
    return result;
})

function addWorkoutValue() {
    if (navigator.vibrate) {
       // Bo iPhone vubec nema/nezna funkci Vibrate!
       navigator.vibrate(60);
    }
    app.logMyActivity(props.activityTypeId, logAmount.value)
}

const watchedUsersSorted = computed(() => {
   const result:Array<{
      id:UserId,
      name:string,
      todayPerformance:number
   }> = [];
   for (const userId in currentActivity.value.watchedUsers) {
      result.push({
         id:userId,
         name:currentActivity.value.watchedUsers[userId].nickName,
         todayPerformance:currentActivity.value.watchedUsers[userId].activityData.valueDay
      })
   }
    return result.sort((a, b) => a.todayPerformance - b.todayPerformance);
})
</script>

<template>
    <div v-if="currentActivity && activitySettings">
        <BaseBox>
            <div class="flex items-center gap-4 w-full">
                <div class="shrink-0" v-if="icon">
                    <IconWorkout :type="icon" class="w-10 h-10" />
                </div>
                <div class="grow">
                    <div class="font-bold text-lg">{{activitySettings.name}}</div>
                    <div class="text-[--base1]">Dnes <b>{{todayPerformanceFormatted}}</b></div>
                </div>
                <FormButton type="button" class="shrink-0" @click="addWorkoutValue">
                    +{{toAddFormatted}}
                </FormButton>
            </div>
            <div class="mt-2">
                <WorkoutGraph :graph-type="activitySettings.unit" :graph-data="{currentValue: todayPerformance, previousValue: recordPerformanceDay}" />
            </div>
        </BaseBox>
        <div v-if="watchedUsersSorted.length" class="bg-[--base3] border-2 border-[--base1] border-t-0 rounded-b-lg mx-4 p-4">
            <div class="flex flex-start justify-around" v-auto-animate>
                <WorkoutTileWatchedUser
                    v-for="user of watchedUsersSorted"
                    :data-type="activitySettings.unit"
                    :key="user.id"
                    :name="user.name"
                    :performance-difference="todayPerformance - user.todayPerformance"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>