<script setup lang="ts">
    import {computed} from "vue";

    const props = withDefaults(defineProps<{
        type: HTMLButtonElement["type"];
        size?: "small" | "medium" | "large";
        disabled?: boolean;
    }>(), {
        size: "medium"
    });

    const sizeStyle = computed(() => {
        switch (props.size) {
            case "small":
                return "py-2";
                break;
            case "medium":
                return "py-3";
                break;
            case "large":
                return "py-4";
                break;
        }
    });
</script>

<template>
    <button
        :type="props.type"
        :class="sizeStyle"
        :disabled="props.disabled"
        class="outline-0 bg-[--base02] text-[--base1] rounded-lg px-4 text-center font-bold border-2 border-[--base02] hover:bg-[--base03] hover:border-[--base03] transition-all disabled:text-[--base00] disabled:bg-[--base1] disabled:border-[--base1] disabled:cursor-default"
    >
        <slot></slot>
    </button>
</template>

<style scoped>

</style>