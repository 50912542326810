<script setup lang="ts">

import ContentWrapper from "@/components/ContentWrapper.vue";
import ContentAnchor from "@/components/ContentAnchor.vue";
import {useApp} from "@/stores/useApp";
import {useRouter} from "vue-router";

const app = useApp();

const notOnProduction = import.meta.env.VITE_NOT_ON_PRODUCTION

const router = useRouter();
async function signOut() {
    await useApp().authSignOut();
    await router.replace({name:"home"})
}

</script>

<template>
    <div class="bg-[--base2] border-b-2 border-b-[--base1] shadow-md py-2 mb-4 sticky top-0 z-20">
        <ContentWrapper>
            <div class="flex items-center justify-between gap-4">
                <RouterLink v-if="router.currentRoute.value.name === 'userSettings'" v-slot="routerProps" :to="{name: 'home'}">
                    <ContentAnchor :anchor="routerProps.href">< zpět na cviky</ContentAnchor>
                </RouterLink>
                <div v-else class="text-[--yellow] font-bold text-lg">FujTuk</div>

                <div v-if="app.currentUser.loggedIn">
                    <ContentAnchor v-if="router.currentRoute.value.name === 'userSettings'" @click.prevent="signOut" anchor="#">Odhlásit</ContentAnchor>
                    <RouterLink v-else v-slot="routerProps" :to="{name:'userSettings'}">
                        <ContentAnchor :anchor="routerProps.href">Účet a nastavení</ContentAnchor>
                    </RouterLink>
                </div>
            </div>
        </ContentWrapper>
        <div v-if="notOnProduction" class="py-1 bg-red-600 -mb-2"></div>
    </div>
</template>

<style scoped>

</style>