<script setup lang="ts">

</script>

<template>
    <div class="my-5 mx-auto w-full h-[3px] bg-[--base1] overflow-hidden relative"></div>
</template>

<style scoped>

</style>