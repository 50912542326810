<script setup lang="ts">
    const props = withDefaults(defineProps<{
        component?: "label" | "div";
    }>(), {
        component: "div",
    })
</script>

<template>
    <component :is="props.component" class="border-2 border-[--base1] bg-[--base3] rounded-lg p-4">
        <slot></slot>
    </component>
</template>

<style scoped>

</style>