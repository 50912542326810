<script setup lang="ts">
import {computed} from "vue";

const props = defineProps<{
    name: string;
    avatar?: string;
}>()
const nameShortened = computed(() => {
    const first = props.name[0];
    const last = props.name[props.name.length - 1];

    const restNormalizedCharacters = props.name.slice(1, props.name.length - 1).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/[aeiou]/gi, "");
    console.log(restNormalizedCharacters)
    const middle = restNormalizedCharacters[0] || "";
    return first+middle+last;
})

</script>

<template>
    <img v-if="props.avatar" :src="props.avatar" class="mx-auto rounded-full w-10 h-10 object-fill" :alt="props.name" />
    <div v-else class="rounded-full bg-[--cyan] w-10 h-10 flex items-center justify-center uppercase font-semibold" :title="props.name">{{nameShortened}}</div>

</template>

<style scoped>

</style>