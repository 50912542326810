<script setup lang="ts">

import PageHeader from "@/components/PageHeader.vue";
import ContentAnchor from "@/components/ContentAnchor.vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import BaseBox from "@/components/BaseBox.vue";
import FormButton from "@/components/FormButton.vue";
import FormInputText from "@/components/FormInputText.vue";
import {computed, ref, watch} from "vue";
import BaseSeparator from "@/components/BaseSeparator.vue";
import BaseTitle from "@/components/BaseTitle.vue";
import {useApp} from "@/stores/useApp";
import {useRouter} from "vue-router";
import FormCheckbox from "@/components/FormCheckbox.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import type {TWorkoutIcon} from "@/icons/workout/iconWorkoutTypes";
import IconWorkout from "@/icons/workout/IconWorkout.vue";
import WorkoutsAllAvailableList from "@/components/WorkoutsAllAvailableList.vue";

const nickName = ref("Marek");
const followUserId = ref("");

const app = useApp();

function onClickShare() {
    navigator.share({
        title: "FujTuk - sleduj kámoše",
        text: `Sleduj jak tvůj kámoš "${currentUser.value.nickName}" cvičí. Jeho ID: ${currentUser.value.idToShare}`,
        url: "https://www.fujtuk.cz/"
    })
}

const watchedUsersValues = ref<Array<string>>([]);

const currentUser = computed(()=>{
    return app.currentUser;
})

type WatchedUsers = Array<{
    userShareId:string,
    name:string,
}>
const watchedUsers = computed<WatchedUsers>(()=>{
    const result:WatchedUsers = [];
    for (const userId in app.watchedUsers) {
        result.push({
            userShareId:app.watchedUsers[userId].shareId,
            name:app.watchedUsers[userId].nickName
        })
    }
    return result;
})

watch(()=>{return currentUser.value.nickName}, (newValue)=>{
    nickName.value = newValue;
},{immediate:true})

async function submitAddUser(userShareId: string) {
   try {
      await app.addWatchedUser(userShareId);
   }
   catch (error) {
      console.error(error);
   }
}

async function doChangeNickName(newNickName: string) {
   try {
      await app.changeMyNickName(newNickName);
   }
   catch (error) {
      console.error(error);
   }
}

</script>

<template>
    <ContentWrapper>
        <BaseBox>
            <div class="flex gap-4 items-center flex-wrap justify-between">
                <div>
                    <span class="text-[--base00] font-bold select-none">ID:</span>
                    <b class="text-[--base03]">{{currentUser.idToShare}}</b>
                </div>
                <FormButton size="small" type="button" @click="onClickShare">Sdílet</FormButton>
            </div>
        </BaseBox>
        <form class="mt-4 flex gap-4 items-end" @submit.prevent="doChangeNickName(nickName)" novalidate>
            <div class="min-w-0">
                <FormInputText id="nick" v-model="nickName">Přezdívka</FormInputText>
            </div>
            <div>
                <FormButton type="submit">Uložit</FormButton>
            </div>
        </form>
    </ContentWrapper>
    <BaseSeparator />
    <ContentWrapper>
        <BaseTitle>Borci, které sleduju</BaseTitle>
        <form @submit.prevent="submitAddUser(followUserId)" class="mt-4 flex gap-4 items-end" novalidate>
            <div class="min-w-0">
                <FormInputText id="followUserId" v-model="followUserId">ID borce</FormInputText>
            </div>
            <div>
                <FormButton type="submit">Přidat</FormButton>
            </div>
        </form>

        <BaseBox v-for="user of watchedUsers" :key="user.userShareId" component="label" :for="user.userShareId" v-auto-animate class="flex gap-4 items-center cursor-pointer select-none mt-4 first:mt-0 justify-start">
<!--            <FormCheckbox :id="user.userShareId" v-model="watchedUsersValues" :value="''" />-->
            <UserAvatar :name="user.name" />
            <div>
                <div class="text-[--base03] text-base font-bold">{{user.name}}</div>
                <div class="text-[--base0] text-sm">{{user.userShareId}}</div>
            </div>
        </BaseBox>
    </ContentWrapper>
    <!--
        <BaseSeparator />
        <ContentWrapper>
            <BaseTitle>Co budu cvičit</BaseTitle>

            <WorkoutsAllAvailableList />
        </ContentWrapper>
    -->
</template>

<style scoped>

</style>