import './main.css'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";

import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://d54cc33aac29c525eef1aad8049373c3@o4507364887166976.ingest.de.sentry.io/4508128389693520",
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/fujtuk\.cz/],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 10% while in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(autoAnimatePlugin);
app.use(createPinia())
app.use(router)

app.mount('#app')
