<script setup lang="ts">
import {RouterView, useRouter} from 'vue-router'
import {useApp} from "@/stores/useApp";
import {computed, ref} from "vue";
import FormCheckbox from "@/components/FormCheckbox.vue";
import PageHeader from "@/components/PageHeader.vue";
import FormButton from "@/components/FormButton.vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import FormInputEmail from "@/components/FormInputEmail.vue";
import ContentAnchor from "@/components/ContentAnchor.vue";
import Registration from "@/views/Registration.vue";

const app = useApp();


const currentUser = computed(()=>{

})

const userIsLoggedIn = computed(()=>{
    return useApp().currentUser.loggedIn;
})

const router = useRouter();


</script>

<template>
    <PageHeader />
    <RouterView v-if="userIsLoggedIn || router.currentRoute.value.name === 'finishSignUp'" v-slot="{ Component }">
        <KeepAlive>
            <component :is="Component" />
        </KeepAlive>
    </RouterView>
    <Registration v-else />
</template>
