<script setup lang="ts">
import {ref} from "vue";
import {useApp} from "@/stores/useApp";
import FormButton from "@/components/FormButton.vue";
import FormInputEmail from "@/components/FormInputEmail.vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import PageHeader from "@/components/PageHeader.vue";

const formSending = ref(false);
const emailIsValid = ref(false);
const showValidation = ref(false);

const app = useApp();

const email = ref<string>("");

async function doRegistration() {
    if (!emailIsValid.value) {
        showValidation.value = true;
        return;
    }

    showValidation.value = false;
    formSending.value = true;
    await app.registerUser(email.value);
    email.value = "";
    emailIsValid.value = false;
    formSending.value = false;
}
function onValidityChanged (validity: ValidityState) {
    emailIsValid.value = validity.valid;
}
</script>

<template>
    <ContentWrapper>
        <form novalidate @submit.prevent="doRegistration" class="flex flex-col gap-4" name="registration">
            <FormInputEmail :valid="emailIsValid" :disabled="formSending" :show-validation="showValidation" required id="regEmail" name="email" v-model="email" @validity-changed="onValidityChanged">E-mail</FormInputEmail>
            <FormButton :disabled="formSending" type="submit" class="w-full">Registrovat</FormButton>
        </form>
       <div class="mt-4 text-xs text-gray-500">
       This site is protected by reCAPTCHA and the Google
       <a class="underline" target="_blank" href="https://policies.google.com/privacy">Privacy Policy</a> and
       <a class="underline" target="_blank" href="https://policies.google.com/terms">Terms of Service</a> apply.
       </div>
    </ContentWrapper>
</template>
