<script setup lang="ts">
import {ref} from "vue";
import type {TWorkoutIcon} from "@/icons/workout/iconWorkoutTypes";
import IconWorkout from "@/icons/workout/IconWorkout.vue";
import BaseBox from "@/components/BaseBox.vue";
import FormCheckbox from "@/components/FormCheckbox.vue";

const workouts = ref<Array<{id: string; name: string; value: string; icon: TWorkoutIcon;}>>([{
    id: "1",
    name: "Kliky",
    value: "pushups",
    icon: "PUSHUP",
}, {
    id: "2",
    name: "Běh",
    value: "run",
    icon: "RUN",
}, {
    id: "3",
    name: "Shyby",
    value: "pullups",
    icon: "PULLUP",
}, {
    id: "4",
    name: "Dřepy",
    value: "squats",
    icon: "SQUAT",
}, {
    id: "5",
    name: "Sedy lehy",
    value: "situps",
    icon: "SITUP",
}])
const selectedWorkoutsValues = ref<Array<string>>([]);

</script>

<template>
    <BaseBox v-for="workout of workouts" component="label" :for="workout.id" class="flex gap-4 items-center cursor-pointer select-none mt-4 first:mt-0">
        <FormCheckbox class="shrink-0" :id="workout.id" v-model="selectedWorkoutsValues" :value="workout.value" />
        <IconWorkout :type="workout.icon" class="w-10 h-10" />
        <div class="text-lg font-bold text-[--base03]">
            {{workout.name}}
        </div>
    </BaseBox>
</template>

<style scoped>

</style>