<script setup lang="ts">

import InputBase from "@/components/FormInputBase.vue";
import {computed} from "vue";

const modelValue = defineModel<string>();
const props = defineProps<{
    id: string;
    name?: string;
}>();

const name = computed(() => {
    return props.name || props.id;
})

</script>

<template>
    <InputBase :id="props.id" :name="name" type="text" v-model="modelValue"><slot></slot></InputBase>
</template>

<style scoped>

</style>