<script setup lang="ts">
    import type {TWorkoutIcon} from "@/icons/workout/iconWorkoutTypes";
    import IconWorkoutPullUp from "@/icons/workout/iconWorkoutPullUp.svg";
    import IconWorkoutPushUp from "@/icons/workout/iconWorkoutPushUp.svg";
    import IconWorkoutRun from "@/icons/workout/iconWorkoutRun.svg";
    import IconWorkoutSitUp from "@/icons/workout/iconWorkoutSitUp.svg";
    import IconWorkoutSquat from "@/icons/workout/iconWorkoutSquat.svg";

    const props = defineProps<{
        type?: TWorkoutIcon,
        class?: string;
    }>()
</script>

<template>
    <template v-if="props.type">
        <IconWorkoutPullUp v-if="props.type == 'PULLUP'" :class="props.class" />
        <IconWorkoutPushUp v-else-if="props.type == 'PUSHUP'" :class="props.class" />
        <IconWorkoutRun v-else-if="props.type == 'RUN'" :class="props.class" />
        <IconWorkoutSitUp v-else-if="props.type == 'SITUP'" :class="props.class" />
        <IconWorkoutSquat v-else-if="props.type == 'SQUAT'" :class="props.class" />
    </template>
</template>

<style scoped>

</style>