import type {ActivityUnit} from "@/stores/useApp";

const metersInKilometer = 1000;
const loacales = "cs-CZ";

export function formatMetersToKilometers(metersCount: number) {
    return new Intl.NumberFormat(loacales, {
        maximumFractionDigits: 2
    }).format(metersCount / metersInKilometer) + "km";
}

export function formatPerformanceDifference(difference: number, dataType: ActivityUnit = "NUMBER") {
    let text = "";

    switch (dataType) {
        case "NUMBER":
            text = difference.toString();
            if (difference > 0) {
                text = `+${difference}`;
            }
            break;
        case "DISTANCE":
            text = formatMetersToKilometers(difference);
            if (difference > 0) {
                text = `+${formatMetersToKilometers(difference)}`;
            }
            break;
    }
    return text;
}

export function performanceDifferenceColor(difference: number) {
    let color = "bg-[--base1]";
    if (difference > 0) {
        color = "bg-[--violet]";
    }
    return color;
}

// https://bito.ai/resources/javascript-get-week-number-javascript-explained/#:~:text=To%20use%20the%20getWeekNumber(d,and%20the%20ISO%20week%20number.
function getWeekNumber(date:Date):number {
    // Copy date so don't modify original
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(( ( (d.valueOf() - yearStart.valueOf()) / 86400000) + 1)/7);
    // Return array of year and week number
    return weekNo;
}

export function isSameMonth(date1:Date, date2:Date): boolean {
    return (date1.getMonth() === date2.getMonth()) && (date1.getFullYear() === date2.getFullYear())
}

export function isSameWeek(date1:Date, date2:Date): boolean {
    return getWeekNumber(date1) === getWeekNumber(date2)
}

export function isSameDay(date1:Date, date2:Date): boolean {
    return date1.toDateString() === date2.toDateString();
}