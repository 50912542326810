<script setup lang="ts">
    const props = defineProps<{
        anchor: string;
        title?: string;
    }>();
</script>
<template>
    <a :href="props.anchor" :title="props.title" class="text-sm underline hover:no-underline font-bold">
        <slot></slot>
    </a>
</template>

<style scoped>

</style>