import { createRouter, createWebHistory } from 'vue-router'
import FinishSignUp from "@/views/FinishSignUp.vue";
import HomePage from "@/views/HomePage.vue";
import Registration from "@/views/Registration.vue";
import UserSettings from "@/views/UserSettings.vue";
import WorkoutSelection from "@/views/WorkoutSelection.vue";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomePage
        },
        {
            path: '/finishsignup:nic(.*)',
            name: 'finishSignUp',
            component: FinishSignUp
        },
        {
            path: '/register',
            name: 'register',
            component: Registration
        },
        {
            path: '/user-settings',
            name: 'userSettings',
            component: UserSettings
        },
        {
            // pokud nemám žádné cviky vybrané, zobrazí se místo homepage tato stránka
            path: '/workout-selection',
            name: 'workoutSelection',
            component: WorkoutSelection
        },
    ]
})

export default router
