<script setup lang="ts" generic="T">
import {computed, onMounted, ref, watch} from "vue";
import type {TAutocomplete} from "@/components/formInputTypes";

const props = withDefaults(
    defineProps<{
        id: HTMLInputElement["id"];
        name?: HTMLInputElement["name"];
        autocomplete?: TAutocomplete;
        type: "email" | "password" | "text" | "number" | "search" | "url" | "tel";
        valid?: boolean;
        invalidMessage?: string;
        invalidMessages?: Partial<{ [key in keyof ValidityState]: string }>;
        showValidation?: boolean;
        inputMode?: HTMLInputElement["inputMode"];
        pattern?: string;
        disabled?: boolean;
        required?: boolean;
    }>(),
    {
        required: false,
        disabled: false,
        readonly: false,
        valid: undefined,
        showValidation: false,
        inputMode: "text",
    },
);

const elementInput = ref<HTMLInputElement | null>(null);
const emit = defineEmits<{
    validityChanged: [validity: ValidityState];
}>();
const inputValue = defineModel<T>();
const validity = ref<ValidityState>({
    valid: false,
    patternMismatch: false,
    typeMismatch: false,
    badInput: false,
    valueMissing: true,
    customError: false,
    rangeOverflow: false,
    tooLong: false,
    tooShort: false,
    stepMismatch: false,
    rangeUnderflow: false,
});

const validState = computed(() => {
    return typeof props.valid !== "undefined" ? props.valid : validity.value.valid;
})

function setValidity() {
    const element = elementInput.value;

    // pro běžný input, nastaví validitu prohlížeč
    let newValidity = element?.validity;

    if (newValidity) {
        validity.value = newValidity;
    }
}
const invalidMessage = computed(() => {
    let message: string | undefined = "";
    if (validity.value) {
        if (validity.value.valueMissing) {
            message = props.invalidMessages?.valueMissing;
        } else if (validity.value.patternMismatch) {
            message = props.invalidMessages?.patternMismatch || props.invalidMessages?.typeMismatch;
        } else if (validity.value.typeMismatch) {
            message = props.invalidMessages?.typeMismatch || props.invalidMessages?.patternMismatch;
        } else if (validity.value.tooLong) {
            message = props.invalidMessages?.tooLong || props.invalidMessages?.tooLong;
        } else if (validity.value.tooShort) {
            message = props.invalidMessages?.tooShort || props.invalidMessages?.tooShort;
        }
    }

    if (!message && props.invalidMessage) {
        message = props.invalidMessage;
    }
    return message ?? "";
})

const dynamicStyles = computed(() => {
    let styles: string = "";

    if (props.disabled) {
        styles = "text-[--base1] bg-[--base2] border-[--base1]";
    } else if (props.showValidation && !props.valid) {
        styles = "text-[--base01] bg-[--base3] border-[--red] focus:border-[--base02]";
    } else {
        styles = "text-[--base01] bg-[--base3] border-[--base01] focus:border-[--base02]";
    }

    return styles;
})
function onInvalid(event: Event) {
    event.preventDefault();
}
function emitValidityAfterChange() {
    if (elementInput.value) {
        const validityToEmit = validity.value;
        elementInput.value.setCustomValidity("");
        if (validityToEmit.valid !== props.valid) {
            emit("validityChanged", validityToEmit);
            return;
        }
    }
}

watch(inputValue, () => {
    setValidity();
    emitValidityAfterChange();
});

onMounted(() => {
    setValidity();
    emitValidityAfterChange();
});
</script>

<template>
    <label :for="props.id" class="flex flex-col gap-2">
        <span class="text-[--base02]">
            <slot></slot>
        </span>
        <input
            ref="elementInput"
            v-model="inputValue"
            :type="props.type"
            :autocomplete="props.autocomplete"
            :id="props.id"
            :disabled="props.disabled"
            :pattern="props.pattern"
            :name="props.name"
            :required="props.required"
            @invalid="onInvalid"
            class="px-4 py-3 border-2 rounded-lg outline-0"
            :class="dynamicStyles"
        />
    </label>
</template>

<style scoped>

</style>