<script setup lang="ts">
import {useApp} from "@/stores/useApp";
import {computed, ref} from "vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import WorkoutTile from "@/components/WorkoutTile.vue";

const app = useApp()

const activityList = computed(() => {
    return app.activityLog;
})

</script>

<template>
    <ContentWrapper>
        <div class="grid gap-4">
            <template v-for="activity in activityList">
                <WorkoutTile :activity-type-id="activity.activityData.activityTypeId"/>
            </template>
        </div>
    </ContentWrapper>
</template>
