<script setup lang="ts">

import InputBase from "@/components/FormInputBase.vue";
import {computed} from "vue";

const modelValue = defineModel<string>();
const props = defineProps<{
    id: string;
    name?: string;
    disabled?: boolean;
    valid?: boolean;
    required?: boolean;
    showValidation?: boolean;
}>();

const name = computed(() => {
    return props.name || props.id;
})
const emit = defineEmits<{
    validityChanged: [validity: ValidityState]
}>();
function onValidityChanged(validity: ValidityState) {
    emit("validityChanged", validity);
}
</script>

<template>
    <InputBase :valid="props.valid" :show-validation="props.showValidation" :required="props.required" :disabled="props.disabled" :id="props.id" :name="name" type="email" v-model="modelValue" @validity-changed="onValidityChanged">
        <slot></slot>
    </InputBase>
</template>

<style scoped>

</style>