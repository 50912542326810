<script setup lang="ts">
import {computed} from "vue";
import {formatPerformanceDifference, performanceDifferenceColor} from "@/utils";
import type {ActivityUnit} from "@/stores/useApp";

const props = withDefaults(defineProps<{
    graphType?: ActivityUnit,
    graphData: {
        previousValue: number,
        currentValue: number,
    }
}>(), {
    graphType: "NUMBER",
})

const graphPercentage = computed<number>(() => {
    let maximumValue = props.graphData.previousValue;
    let currentValue = props.graphData.currentValue;
    if (currentValue > maximumValue) {
        maximumValue = currentValue;
        currentValue = props.graphData.previousValue;
    }
    return 100 / maximumValue * currentValue;
})
const graphStyle = computed<string>(() => {
    return `width: ${graphPercentage.value}%`;
})
const valueDifference = computed(() => {
    return props.graphData.currentValue - props.graphData.previousValue;
})
const graphText = computed<string>(() => {
    return formatPerformanceDifference(valueDifference.value, props.graphType);
})
const graphColor = computed<string>(() => {
    return performanceDifferenceColor(valueDifference.value);
})
</script>

<template>
    <div class="grow flex items-center gap-2">
        <div class="h-5 relative w-full rounded-full overflow-hidden text-right pr-2 transition-all" :class="graphColor">
            <div class="absolute rounded-full left-0 inset-y-0 bg-[--green] transition-all" :style="graphStyle"></div>
            <div class="relative z-10 text-[--base3] text-sm">{{graphText}}</div>
        </div>
    </div>
</template>

<style scoped>

</style>