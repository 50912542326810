<script setup lang="ts">

</script>

<template>
    <div class="text-xl font-bold text-[--base03] my-5">
        <slot></slot>
    </div>
</template>

<style scoped>

</style>