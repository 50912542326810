<script setup lang="ts">
import {ref, watch} from "vue";
import {useApp} from "@/stores/useApp";
import FormButton from "@/components/FormButton.vue";
import PageHeader from "@/components/PageHeader.vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import FormInputEmail from "@/components/FormInputEmail.vue";
import {useRouter} from "vue-router";


const email = ref<string>(window.localStorage.getItem('emailForSignIn')?? '');
const formSending = ref(false);
const emailIsValid = ref(false);
const showValidation = ref(false);

const app = useApp();
const router = useRouter();

watch(()=>useApp().currentUser.loggedIn,(value)=>{
    if (value) {
        // Uzivatel je zaregistrovany, jdeme na home
        router.replace({name:"home"})
    }
});

async function doFinishSignIn() {
    if (!emailIsValid.value) {
        showValidation.value = true;
        return;
    }
    showValidation.value = false;
    formSending.value = true;

    await app.finishSighIn(email.value);
    email.value = "";
    emailIsValid.value = false;
    formSending.value = false;
}
function onValidityChanged (validity: ValidityState) {
    emailIsValid.value = validity.valid;
}

</script>

<template>
    <ContentWrapper>
        <form novalidate @submit.prevent="doFinishSignIn" class="flex flex-col gap-4" name="registration">
            <FormInputEmail :valid="emailIsValid" :disabled="formSending" :show-validation="showValidation" required @validity-changed="onValidityChanged" id="regEmail" name="email" v-model="email">E-mail</FormInputEmail>
            <FormButton :disabled="formSending" type="submit" class="w-full">Dokončit registraci</FormButton>
        </form>
    </ContentWrapper>
</template>
